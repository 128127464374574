<template>
  <div>
    <div class="main-user">
      <el-container>
        <el-header class="main-user-header">
          <div>
          </div>
          <div class="main-user-header-buttion">
            <el-button round type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add(null)">新增用户
            </el-button>
          </div>
        </el-header>
        <el-main class="main-center">
          <div class="main-table">
            <el-table :data="tableData" stripe style="width: 100%" size="small">
              <el-table-column align="center" prop="userName" label="用户名" width="180">
              </el-table-column>
              <el-table-column align="center" prop="phone" label="账号 / 手机号" width="180">
              </el-table-column>
              <el-table-column align="center" prop="nickName" label="昵称">
              </el-table-column>
              <el-table-column align="center" prop="roleNameSplit" label="角色">
              </el-table-column>
              <el-table-column align="center" prop="lastLogin" label="最近登录">
              </el-table-column>
              <el-table-column label="操作" align="center" width="600">
                <template slot-scope="props">
                  <el-button round size="mini" type="info" v-if="props.row.isShowMessageBtn"
                    @click="sendMessage(props.row)">消息推送</el-button>
                  <span v-for="arr in buttionArr" class="user-span"
                    :hidden="arr.code == 'del' && props.row.userName == 'admin'">
                    <el-button round :type="arr.styleDisplay" :icon="arr.icon" size="mini"
                      @click="opeate(arr, props.row)">{{ arr.menuName }}</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="main-page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizeArr" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </el-main>
      </el-container>

    </div>
    <!-- Form -->

    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="400px">
      <el-form :model="from" :rules="rules" ref="from" label-width="100px" class="demo-ruleForm" label-position="left">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="from.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="from.phone"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="passWord">
          <el-input v-model="from.passWord" :show-password="true"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right; margin: 0">
          <el-button type="primary" @click="submitForm('from')">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="用户关联角色" :visible.sync="dialogRoleVisible" width="400px">
      <el-form label-width="100px" class="demo-ruleForm" label-position="left">
        <el-form-item label="角色">
          <el-select v-model="roleKey" multiple filterable placeholder="请选择">
            <el-option v-for="item in roleArray" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align: right; margin: 0">
          <el-button type="primary" @click="submitRoleForm()">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="服务器权限" :visible.sync="dialogServerVisible" width="450px">
      <el-form label-width="100px" class="demo-ruleForm" label-position="left">
        <el-form-item label="服务器">
          <el-select style="width: 300px;" v-model="serverKey" multiple filterable placeholder="请选择">
            <el-option v-for="(item,index) in serverAcounts" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align: right; margin: 0">
          <el-button type="primary" @click="submitServerForm()">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-drawer style="margin-left:13%" title="我是标题" direction="ttb" size="200px" :visible.sync="drawer"
      :with-header="false">
      <div style="padding:30px;line-height: 28px;">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedPower" @change="handleCheckedChange">
          <el-checkbox v-for="item in powers" :label="item.name" :key="item.id">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </div>

      <div class="demo-drawer-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="submitPowerForm" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import powerApi from "@/api/powerMenu.js";
import requestApi from "@/api/user.js";
import { webSocketUrl } from "@/util/request.js";
import { webSocketInfo } from "@/util/common.js";
import serverApi from "@/api/server.js";
let websocketUtil = null

export default {
  props: ["buttions"],
  data() {
    return {
      userId: null,
      roleKey: [],
      serverKey:[],
      tableData: null,
      total: 10,
      pageIndex: 1,
      pageSize: 10,
      pageSizeArr: [10, 20, 50, 100],
      currentPage: 1,
      buttionArr: null,
      dialogFormVisible: false,
      dialogRoleVisible: false,
      dialogServerVisible:false,
      from: { id: "", userName: "", phone: "" },
      title: "新增",
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        //path: [{ required: true, message: "请输入地址", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          }
        ]
      },
      roleArray: null,
      drawer: false,
      loading: false,
      checkAll: false,
      checkedPower: [],
      powers: [],
      isIndeterminate: true,
      powerIds: [],
      messageValue: "",
      serverAcounts:[]
    };
  },

  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getPage();
    this.getMenuButtions();
  },
  mounted() {
    websocketUtil = webSocketInfo(webSocketUrl);

  },
  computed: {},
  //生命周期 - 挂载完成（访问DOM元素）
  methods: {
    sendMessage(row) {

      this.$prompt('请输入消息内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.messageValue
      }).then(({ value }) => {

        this.messageValue = value;
        let sendClientId = "home_" + row.id;
        let message = { clientId: sendClientId, type: 4, message: this.messageValue }
        websocketUtil.websocket.send(JSON.stringify(message))
        this.sendMessage(row)
      })
    },
    getPage() {

      this.page = { pageIndex: this.pageIndex, pageSize: this.pageSize };
      this.$post(requestApi.page, this.page).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    getAccounts() {
      let params = {};
      this.$get(serverApi.getAccounts, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.serverAcounts = res.data;
          console.log(JSON.stringify(this.serverAcounts) )
          const serverGroupedBy = [];
          for (const item of this.serverAcounts) {
            if(serverGroupedBy.findIndex(f=>f===item.group)<0){
              serverGroupedBy.push(item.group)  
            }
          }
          this.serverAcounts = serverGroupedBy;
        }
      });
    },
    getMenuButtions() {
      this.buttionArr = JSON.parse(localStorage.getItem("menuButtions"));
    },
    add(row) {

      this.dialogFormVisible = true;
      this.title = "新增";
      if (this.$refs["from"] !== undefined) {
        this.$refs["from"].resetFields();
      }
      this.from.id = 0;
    },
    edit(row) {

      this.dialogFormVisible = true;
      this.title = "编辑";
      this.from.id = row.id;
      this.from.userName = row.userName;
      this.from.phone = row.phone;

    },
    del(row) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$del(requestApi.del, { userId: row.id }).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.$msg_ok("删除成功");
            this.getPage();
          }
        });
      });
    },
    linkRole(row) {
      this.dialogRoleVisible = true;
      this.roleKey = [];
      this.userId = row.id;
      this.$get(requestApi.roleSelect, { id: this.userId }).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.roleArray = res.data;
          row.userRoles.forEach((element) => {
            this.roleKey.push(element.roleId);
          });
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.from.id == null) {
            this.from.id == 0;
          }

          if (this.from.id == 0) {
            this.$post(requestApi.add, this.from).then((res) => {
              if (res.success && res.statusCode == 200) {

                this.getPage();
              }
              this.dialogFormVisible = false;
            });
          } else {
            this.$post("/user/edit", this.from).then((res) => {
              if (res.success && res.statusCode == 200) {
                this.$msg_ok("更新成功");
                this.getPage();
              }
              this.dialogFormVisible = false;
            });
          }
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPage();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getPage();
    },
    opeate(row, item) {
      this.userId = item.id;
      //编辑
      if (row.code == "edit") {
        this.edit(item);
      }
      //删除
      else if (row.code == "del") {
        this.del(item);
      }
      //用户关联角色
      else if (row.code == "role") {
        this.linkRole(item);
      } else if (row.code == "tab") {
        this.drawer = true;
        this.getPowerList();
      
        //清空所选内容
        this.handleCheckAllChange(false);
        //初始化所选内容
        this.initPowerIds(item);
        this.checkAll = false;
      }else if(row.code == "服务器权限"){
        this.dialogServerVisible = true
        this.serverKey=item.serverLabels;
        this.getAccounts();
      }
    },
    submitRoleForm() {
      var json = { id: this.userId, list: this.roleKey };
      
      this.$post(requestApi.userLinkRole, json).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.getPage();
        }
      });
      this.dialogRoleVisible = false;
    },
    submitServerForm(){
      var json = { userId: this.userId, list: this.serverKey };
      this.$post(requestApi.linkServer, json).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.getPage();
        }
      });
      this.dialogServerVisible = false;
    }
    ,//获取增值菜单列表
    getPowerList() {
      this.$get(powerApi.list, {}).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.powers = res.data;
        }
      });
    },
    cancelForm() {
      this.drawer = false;
    },
    //提交增值权限
    submitPowerForm() {
      console.log(this.powerIds)

      let from = { key: this.userId, values: this.powerIds };

      this.$post(requestApi.bindPowerIds, from).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.drawer = false;
          this.getPage();
        }
      });
    },
    handleCheckAllChange(val) {
      this.checkedPower = val ? this.powers.map(f => { return f.name }) : [];
      this.isIndeterminate = false;
      if (val) {
        //全选
        this.powerIds = this.powers.map(f => { return f.name });
      } else {
        this.powerIds = [];
      }
    },
    //初始化增值服务的id
    initPowerIds(row) {
      this.checkedPower = row.powerIds;
      this.powerIds = row.powerIds;
    },
    handleCheckedChange(value) {
      this.powerIds = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.powers.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.powers.length;
    }
  },
};
</script>
<style lang="scss" scoped>
.main-user .el-main {
  padding: 5px;
}

.main-user .el-header {
  padding: 5px;
}

.main-user-header-buttion {
  margin-right: 20px;
}

.main-user-header {
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
}

.main-center .main-page {
  float: right;
}

.user-span {
  margin-left: 10px;
}

.demo-drawer-footer {
  position: fixed;
  top: 130px;
  left: 80%;
}
</style>
